/* ----------------------------------------------------
// Header change on scroll
// ------------------------------------------------- */

$(document).ready(function () {
	$(function () {
		$(document).scroll(function () {
			var $nav = $('.site-header');
			$nav.toggleClass('header-scrolled', $(this).scrollTop() > $nav.height());
		});
	});
});
