// ----------------------------------------------------
// Hide header on scroll
// ----------------------------------------------------

$(document).ready(function () {
	'use strict';

	var c,
		currentScrollTop = 0,
		header = $('header');

	$(window).scroll(function () {
		var a = $(window).scrollTop();
		var b = header.height() + 50;

		currentScrollTop = a;

		if (c < currentScrollTop && a > b + b) {
			header.addClass('scroll-up');
		} else if (c > currentScrollTop && !(a <= b)) {
			header.removeClass('scroll-up');
		}

		c = currentScrollTop;
	});
});
