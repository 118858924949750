/* Vendor files ------------------------------------ */

import hideOnScroll from './partial/hide-on-scroll.js';
import changeOnScroll from './partial/header-change-on-scroll.js';
import aos from './partial/aos.js';
import anchor from './partial/anchor-highlight-section.js';

// Accordion
$('.accordion-trigger').click(function () {
	$(this).toggleClass('active');
	$(this).next().slideToggle(200).parent().toggleClass('is-active');
});

$('#showNumber').click(function () {
	var tel = $(this).data('last');
	$(this)
		.find('span')
		.html('<a href="tel:' + tel + '">' + tel + '</a>');
});

$('#showEmail').click(function () {
	var email = $(this).data('last');
	$(this)
		.find('span')
		.html('<a href="mailto:' + email + '">' + email + '</a>');
});

// Adjusted version of https://codepen.io/bramus/pen/yikfd
// This version also reverses the animation when elements that got slide into view
// succesively slide out of view again.

// In case you're wondering about that `.css('top', $animatable.css('top'))` part:
// -> it's the magic part in this code as it triggers layout, so the browser will
// render after having deleted the animate-in class and having added the animate-out
// class. That way the animation-play-state will actually change from running to
// paused to running again, thus triggering the start of the animation

jQuery(function ($) {
	// Function which adds the 'animated' class to any '.animatable' in view
	var doAnimations = function () {
		// Calc current offset and get all animatables
		var offset = $(window).scrollTop() + $(window).height(),
			$animatables = $('.animatable');

		// Check all animatables and animate them if necessary
		$animatables.each(function (i) {
			var $animatable = $(this);

			// Items that are "above the fold"
			if ($animatable.offset().top + $animatable.height() + 50 < offset) {
				// Item previously wasn't marked as "above the fold": mark it now
				if (!$animatable.hasClass('animate-in')) {
					$animatable.removeClass('animate-out').css('top', $animatable.css('top')).addClass('animate-in');
				}
			}

			// Items that are "below the fold"
			// else if ($animatable.offset().top + $animatable.height() + 50 > offset) {
			// 	// Item previously wasn't marked as "below the fold": mark it now
			// 	if ($animatable.hasClass('animate-in')) {
			// 		$animatable.removeClass('animate-in').css('top', $animatable.css('top')).addClass('animate-out');
			// 	}
			// }
		});
	};

	// Hook doAnimations on scroll, and trigger a scroll
	$(window).on('scroll', doAnimations);
	$(window).trigger('scroll');
});

// $(document).ready(function () {
// 	$('.animated-img').each(function () {
// 		var $el = $(this),
// 			staticSrc = $el.attr('src'),
// 			gifSrc = $el.data('gifSrc');
// 		$el.hover(
// 			function () {
// 				$(this).attr('src', gifSrc);
// 			},
// 			function () {
// 				$(this).attr('src', staticSrc);
// 			}
// 		);
// 	});
// });

// function animateImg(id, gifSrc) {
// 	var $el = $(id),
// 		staticSrc = $el.attr('src');
// 	$el.hover(
// 		function () {
// 			$(this).attr('src', gifSrc);
// 		},
// 		function () {
// 			$(this).attr('src', staticSrc);
// 		}
// 	);
// }

// $(document).ready(function () {
// 	animateImg('.id1', 'img/giphy.gif');
// 	animateImg('.id2', 'img/giphy.gif');
// });

new Freezeframe();

// window.addEventListener('resize', resizeCanvas, false);

// resizeCanvas(); /// call the first time page is loaded

// function resizeCanvas() {
// 	canvas.width = window.innerWidth;
// 	canvas.height = window.innerHeight;
// }

$(document).ready(function () {
	$('.animated-img').each(function () {
		var $el = $(this),
			staticSrc = $el.attr('src'),
			gifSrc = $el.data('gifSrc');
		$el.hover(
			function () {
				$(this).attr('src', gifSrc);
			},
			function () {
				$(this).attr('src', staticSrc);
			}
		);
	});
});
